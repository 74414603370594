var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    class: _vm.className,
    attrs: {
      "viewBox": _vm.viewBox,
      "fill": "currentColor"
    }
  }, [_vm._t("default")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }