var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('g', [_c('path', {
    attrs: {
      "d": "M27.1,25.7L25.8,27l-5.7-5.7L14.4,27l-1.3-1.3l5.7-5.7l-5.7-5.7l1.3-1.3l5.7,5.7l5.7-5.7l1.3,1.3L21.4,20L27.1,25.7z"
    }
  }), _c('path', {
    staticStyle: {
      "opacity": "0.5"
    },
    attrs: {
      "d": "M20.1,40C9,40,0,31,0,20C0,9,9,0,20.1,0c11.1,0,20.1,9,20.1,20C40.2,31,31.2,40,20.1,40z M20.1,1C9.6,1,1,9.5,1,20c0,10.5,8.6,19,19.1,19s19.1-8.5,19.1-19C39.2,9.5,30.6,1,20.1,1z"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }