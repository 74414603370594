<template>
    <router-view :key="$i18n.locale" />
</template>

<script>
export default {
    data() {
        return {
            timer: null,
            latest: Date.now(),
        };
    },
    created() {
        this.init();
    },
    methods: {
        init() {
            document.addEventListener("mousemove", this.reset, false);
            document.addEventListener("mousedown", this.reset, false);
            document.addEventListener("keypress", this.reset, false);
            document.addEventListener("touchmove", this.reset, false);
            document.addEventListener("onscroll", this.reset, false);

            this.onTimer();
        },
        reset() {
            this.latest = Date.now();
        },
        onTimer() {
            clearTimeout();
            this.timer = setTimeout(() => {
                if (
                    24 * 60 * 60 * 1000 < Date.now() - this.latest ||
                    this.payload.exp * 1000 <= Date.now()
                ) {
                    this.$store.dispatch("logout");
                }
                this.onTimer();
            }, 1000);
        },
    },
    computed: {
        refreshToken() {
            return this.$store.state.refreshToken;
        },
        accessToken() {
            return this.$store.state.accessToken;
        },
        payload() {
            return JSON.parse(
                atob(this.refreshToken?.split(".")[1] || "") || "{}"
            );
        },
    },
    watch: {
        accessToken() {
            if (!this.accessToken) {
                alert("로그아웃 되었습니다");
                this.$router.push("/");
            }
        },
    },
};
</script>
