<template>
    <svg
        :class="className"
        :viewBox="viewBox"
        fill="currentColor"
    >
        <slot />
    </svg>
</template>

<script>
export default {
    props: {
        className: { type: String, default: "" },
        viewBox: { type: String, default: "0 0 24 24" },
        // width: { type: [Number, String], default: "" },
        // height: { type: [Number, String], default:"" },
        iconName: { type: String, default: "" },
        // iconColor: { type: String, default: "currentColor" },
    },
};
</script>